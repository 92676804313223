.Title {
	text-align: center;	

	h1 {
		display: inline-block;		
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: auto;
		font-size: 2.25rem;
		line-height: 2.5rem;		

		img {
			position: absolute;
			top: -2rem;
			right: -2rem;
			width: 2.5rem;
			z-index: -10;
		}
	}	

	h2 {
		position: relative;
		margin-top: 2.5rem;
		text-align: center;
		max-width: 24rem;
		margin-left: auto;
		margin-right: auto;
		font-size: 1.9rem;
		line-height: 2.25rem;
	
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			right: auto;
			top: -20px;
			transform: translate(-50%, -50%);
			height: 4px;
			width: 150px;
			opacity: 1;
			background-color: var(--pink);
		}
	}
}

.h1 {
	padding-top: 3rem;
}

.h2 {
	overflow-x: hidden;
}

@media (min-width: 768px) {
	.Title {
		h2 {
			margin-left: 0;
			margin-right: 0;
			max-width: max-content;			

			&::after {
				left: 105%;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 4px;
				width: 100vw;
			}
		}
	}
}

@media (min-width: 640px) {
	.Title {
		h1 {
			font-size: 3.75rem;
			line-height: 1;
		}
	}	
}