.backdrop {
	position: fixed;
	right: -125vw;
	top: -125vw;
	width: 250vw;
	height: 250vw;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.9);
	opacity: 1;
	visibility: visible;
	transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
	z-index: 1;

	&.close {
		top: 25px;
		right: 25px;
		width: 0;
		height: 0;
		opacity: 0;
		visibility: hidden;
	}
}

// .menuItem {
// 	&.active {
// 		background-color: rgba(255, 255, 255, .08);
// 		color: var(--pink);
// 	}
// }
.menuToggle {
	position: relative;
	margin-top: 2rem;
	padding: 0;
	width: 50px;
	height: 50px;
	border: none;
	border-radius: 4px;
	outline: none;
	background-color: var(--pink);
	cursor: pointer;
	z-index: 3;

	&.open {
		.burger {
			width: 0;

			&::before {
				transform: rotate(140deg) translate(20px, 5px);
			}

			&::after {
				transform: rotate(-140deg) translate(20px, -3px);
			}
		}
	}

	.burger {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 34px;
		height: 3px;
		background: var(--white);
		border: none;
		border-radius: 4px;
		transform: translate(-50%, -50%);
		transition: all .5s;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 34px;
			height: 3px;
			background: var(--white);
			border: none;
			border-radius: 4px;
			transform: translate(-50%, -50%);
		}

		&:before {
			margin-top: -10px;
		}

		&:after {
			margin-top: 10px;
		}
	}
}

.drawer {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	visibility: visible;
	transform: translate(0, 0);
	transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
	z-index: 3;

	&.close {
		opacity: 0;
		visibility: hidden;
		transform: translate(100%, -100%);
	}

	ul {
		position: relative;
		text-align: center;
		padding: 0;
		width: 100%;
		list-style: none;

		a {
			display: block;
			padding: 15px 0;
			text-transform: uppercase;
			font-size: 20px;
			color: var(--white);
			text-decoration: none;
			outline: none;
			letter-spacing: 4px;
			transition: background-color .7s ease;

			&:hover {
				background-color: rgba(255, 255, 255, .08);
				color: var(--pink);
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.backdrop {
		width: 350vw;
		height: 350vw;
	}
}