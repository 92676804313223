.CTA {
	position: relative;
	padding-bottom: 11rem;
	text-align: center;
	overflow: hidden;

	h2 {
		margin-bottom: 2.5rem;
		font-size: 2.25rem;
		line-height: 2.5rem;
	}
}

.lineBreakIcon {
	margin-bottom: 3.5rem;	
	width: 238px;
	height: 17px;
}

.fancyLinesIcon {
	position: absolute;
	object-fit: cover;
	left: 50%;
	bottom: -70px;
	min-width: 800px;
	transform: translate(-50%, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
	z-index: -10;
}

@media (min-width: 640px) {
	h2 {
		font-size: 3rem;
		line-height: 1;
	}
	
	.fancyLinesIcon {
		min-width: 1100px;
		bottom: -125px;
	}
}