.Projects {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 2rem;
	align-items: flex-start;
	margin-bottom: 4rem;
}

.btnWrap {
	text-align: center;
	margin-bottom: 2.5rem;
}

@media (min-width: 768px) {
	.Projects {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}
