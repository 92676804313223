.Copyright {
	max-width: 56rem;
	border-top: 1px solid var(--pink);
	margin: auto;
	padding: 2rem 1rem 0 1rem;
	text-align: center;
	
	p {
		font-size: .8rem;
		line-height: 1rem;		
	}

	.info {
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: .1em;
		margin-bottom: 1rem;
	}

	a {
		display: inline-block;
		font-weight: 600;
	}
}