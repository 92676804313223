.ProjectCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 24rem;
	text-align: left;
	margin-bottom: 2rem;

	.linkImg,
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		box-sizing: border-box;
	}

	.linkImg {
		border: 1px solid var(--pink);
		border-radius: 0.75rem;
		margin-bottom: 1.25rem;
		padding: 0.5rem;
		height: 334px;
		transition: all .15s cubic-bezier(.4, 0, .2, 1);

		&:hover {
			transform: translateY(-0.5rem);			
			opacity: 0.75;
		}
	}

	img {
		border-radius: 0.375rem;
	}

	.contentItem {
		display: flex;
		flex-direction: column;
		width: 100%;

		h3 {
			font-size: 1.125rem;
			line-height: 1.75rem;
			margin-bottom: 0;
		}

		p {
			flex-grow: 1;
			margin: 0;
			font-size: .875rem;
			line-height: 1.25rem;
			min-height: 40px;
		}

		.tags {
			display: flex;
			flex-wrap: wrap;
			margin: 0.5rem 0 0 -.5rem;
			padding: 0;
		}

		.tag {
			margin: 0.25rem;
			padding: 0.25rem .5rem;
			font-size: .875rem;
			line-height: 1.25rem;
			color: var(--white);
			border-radius: 0.5rem;
			background: var(--pink) linear-gradient(45deg, rgba(0, 0, 0, 0) 20%, hsla(0, 0%, 100%, .5) 35%, hsla(0, 0%, 100%, .5) 65%, rgba(0, 0, 0, 0) 80%) -100% 50%/50% 100% no-repeat;
			transition: background .8s;
			cursor: pointer;

			&:hover {
				background: var(--pink) linear-gradient(45deg, rgba(0, 0, 0, 0) 20%, hsla(0, 0%, 100%, .5) 35%, hsla(0, 0%, 100%, .5) 65%, rgba(0, 0, 0, 0) 80%) 200% 150%/50% 100% no-repeat;
			}
		}
	}
}

.headerBlock {
	display: flex;
	justify-content: space-between;
}

.linkIcons {
	display: flex;
	align-items: flex-start;

	& > a {
		color: var(--pink);		

		&:last-child {
			margin-left: 5px;
		}
	}
}

@media (max-width: 768px) {
	.ProjectCard {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
}