.reviews {
	max-width: 28rem;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 9rem;
}

.reviewWrap {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 2.5rem;
	max-width: 64rem;
	margin: auto;
}

@media (min-width: 768px) {
	.reviews {
		max-width: 100%;
	}
	.reviewWrap {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		max-height: 200px;
	}
}